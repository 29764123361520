import React, { useState, useEffect } from "react";

// datatable
import "jquery/dist/jquery.min.js";

import "datatables.net-dt/js/dataTables.dataTables";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";

import "./App.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import moment from "moment";
import { API_URL } from "./Global";
import {
    Col,
    Row,
    ButtonGroup,
    ToggleButton,
    Modal,
    Navbar,
    Container,
    Nav,
    NavDropdown,
} from "react-bootstrap";
import { Autocomplete } from "@mui/material";
import { HashRouter, Routes, Route, Link } from "react-router-dom";

import Login from "./Components/auth/Login";
import GSTBill from "./Components/GSTBill/GSTBill";
import NONGSTBill from "./Components/NONGSTBILL/NONGSTBill";
import QuoteHistory from "./Components/Quotation/QuoteHistory";
import GSTBillHistory from "./Components/GSTBill/GSTBillHistory";
import CLedgerManager from "./Components/ledger_manager/LedgerManager";
import NONGSTBillHistory from "./Components/NONGSTBILL/NONGSTBillHistory";
import ExpensesManager from "./Components/ExpensesManger/ExpensesManager";
import Quote from "./Components/Quotation/Quote";
import PartyManager from "./Components/PartyManager";
import WorkerManager from "./Components/worker_manager/WorkerManager";

// import Analytics from "./Components/analytics/Analytics";
// import GSTNONGSTHistory from "./Components/GSTNONGSTHistory";
import CPresentyManager from "./Components/presenty_manager/PresentyManager";
// datatable
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

const axios = require("axios");

export const FilterContext = React.createContext();
export const AuthContext = React.createContext();

export default function App() {
    // this.state = {
    //     id : null,
    //     name : null,
    //     unit : null,
    //     quantity : null,

    //     currentProduct_id: null,
    //     currentProduct_name: null,
    //     currentProduct_unit: null,
    //     currentSite_quantity: null,

    //     updateModal: false,
    //     viewModal: false,
    //     ManagersModal: false,
    // };

    const [isLoggedIn, setIsLoggedIn] = useState(
        localStorage.getItem("isLoggedIn") == "true" ? true : false
    );
    const [userType, setUserType] = useState(
        localStorage.getItem("userType") != "0"
            ? Number(localStorage.getItem("userType"))
            : false
    );
    const Unit = ["sq.ft", "unit"];
    const [products, setProducts] = useState(null);
    const [currentProduct, setCurrentProduct] = useState("");
    const [currentUnit, setCurrentUnit] = useState(0);
    const [currentUnitPrice, setCurrentUnitPrice] = useState(0);
    const [currentWidth, setCurrentWidth] = useState(1);
    const [currentHeight, setCurrentHeight] = useState(1);
    const [currentSqft, setCurrentSqft] = useState(1);
    const [currentQuantity, setCurrentQuantity] = useState(0);
    const [currentPrice, setCurrentPrice] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const [currentId, setCurrentId] = useState(0);

    const [activeTab, setActiveTab] = useState(0);

    const [show, setShow] = useState(false);

    const handleShow = (quantity, id, price) => {
        setCurrentQuantity(quantity);
        setCurrentId(id);
        setCurrentUnitPrice(price);

        setShow(true);
        console.log(quantity, id, price);
    };

    // if (currentUnit === 1) {
    //     setCurrentUnit("sq.ft")
    // }else{
    //     setCurrentUnit("unit")
    // }

    const insertNewProduct = () => {
        let url = API_URL;
        // const query = `SELECT CONCAT(id, ', ', name) AS name, address FROM party;`;
        const query = `INSERT INTO  products(name, price, quantity, unit) values('${currentProduct}',${currentUnitPrice},${currentQuantity},${currentUnit} )`;

        console.log(query);

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("insert successful");
                toast.success("Product added successfully");
                setTimeout(() => {
                    fetchProductList();
                }, 3000);
            })
            .catch((err) => {
                console.log("products insert error: ", err);
            });
    };

    const submitProductUpdate = () => {
        let url = API_URL;
        const query = `UPDATE products SET quantity = ${currentQuantity},price = ${currentUnitPrice} where id = ${currentId}`;

        console.log(query);

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log(res.data);

                toast("Product updated successfully!");
                setShow(false);
                setTimeout("window.location.reload()", 4000);
            })
            .catch((error) => {
                console.log(error);
            });

        // this.setState({ quantity: ""});
    };

    const deleteProduct = (id) => {
        let url = API_URL;
        // const query = `SELECT CONCAT(id, ', ', name) AS name, address FROM party;`;
        const query = `update products set status = 0 where id = ${id}`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("delete successful");
                toast.error("Product deleted successfully");
                setTimeout(() => {
                    fetchProductList();
                }, 3000);
            })
            .catch((err) => {
                console.log("products fetch error: ", err);
            });
    };

    const updateSqft = (type, value) => {
        let multiplier =
            currentUnit === "Sq.ft." ? 1 : currentUnit === "Inch" ? 144 : 92903;
        if (type === "width") {
            setCurrentSqft(
                (
                    ((value * currentHeight) / multiplier) *
                    currentQuantity
                ).toFixed(2)
            );
        } else if (type === "height") {
            setCurrentSqft(
                (
                    ((currentWidth * value) / multiplier) *
                    currentQuantity
                ).toFixed(2)
            );
        } else if (type === "unit") {
            let multiplier =
                value === "Sq.ft." ? 1 : value === "Inch" ? 144 : 92903;
            setCurrentSqft(
                (
                    ((currentWidth * currentHeight) / multiplier) *
                    currentQuantity
                ).toFixed(2)
            );
        } else if (type === "quantity") {
            setCurrentSqft(
                (((currentWidth * currentHeight) / multiplier) * value).toFixed(
                    2
                )
            );
        }
    };

    const updateCurrentPrice = (type, value) => {
        let divisor =
            currentUnit === "Sq.ft." ? 1 : currentUnit === "Inch" ? 144 : 92903;

        if (type === "unit") {
            setCurrentUnit(value);
            let divisor =
                value === "Sq.ft." ? 1 : value === "Inch" ? 144 : 92903;
            setCurrentPrice(
                (
                    (currentWidth *
                        currentHeight *
                        currentUnitPrice *
                        currentQuantity) /
                    divisor
                ).toFixed(2)
            );
        } else if (type === "unitPrice") {
            setCurrentUnitPrice(value);
            setCurrentPrice(
                (
                    (currentWidth * currentHeight * value * currentQuantity) /
                    divisor
                ).toFixed(2)
            );
        } else if (type === "quantity") {
            setCurrentQuantity(value);
            setCurrentPrice(
                (
                    (currentWidth * currentHeight * currentUnitPrice * value) /
                    divisor
                ).toFixed(2)
            );
        } else if (type === "product") {
            setCurrentUnitPrice(value);
            setCurrentPrice(
                (
                    (currentWidth * currentHeight * value * currentQuantity) /
                    divisor
                ).toFixed(2)
            );
        } else if (type === "width") {
            setCurrentWidth(value);

            setCurrentPrice(
                (
                    (value *
                        currentHeight *
                        currentUnitPrice *
                        currentQuantity) /
                    divisor
                ).toFixed(2)
            );
        } else if (type === "height") {
            setCurrentHeight(value);
            setCurrentPrice(
                (
                    (currentWidth *
                        value *
                        currentUnitPrice *
                        currentQuantity) /
                    divisor
                ).toFixed(2)
            );
        }
        updateSqft(type, value);
    };

    const fetchProductList = (currentUnit) => {
        let url = API_URL;
        // const query = `SELECT CONCAT(id, ', ', name) AS name, address FROM party;`;
        const query = `select id, name as label, price,quantity,unit from products where status = 1`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                setProducts(res.data, initializeDataTable);

                // init data table
            })
            .catch((err) => {
                console.log("products fetch error: ", err);
            });
    };

    const initializeDataTable = () => {
        $("#productTable")
            .DataTable({
                destroy: true,
                dom:
                    "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
                    "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
                    "<'row'<'col-sm-12' tr>>" +
                    "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
                responsive: true,
                lengthChange: false,
                autoWidth: false,
                buttons: ["csv", "print"],
                order: [[0, "desc"]],
            })
            .buttons()
            .container()
            .appendTo("#productTable_wrapper .col-md-6:eq(0)");
    };

    useEffect(() => {
        fetchProductList();
    }, []);

    const refresh = () => {
        window.location.reload(false);
    };

    return (
        <div>
            <AuthContext.Provider
                value={[
                    [isLoggedIn, setIsLoggedIn],
                    [userType, setUserType],
                ]}
            >
                <HashRouter>
                    {isLoggedIn && (
                        <div className="container">
                            <div class="row"></div>
                            <Navbar
                                collapseOnSelect
                                expand="lg"
                                className="mt-3 text-dark"
                                style={{
                                    backgroundColor: "#badcf9",
                                    height: "50px",
                                }}
                            >
                                <Container
                                    style={{
                                        backgroundColor: "#badcf9",
                                        zIndex: "100",
                                    }}
                                >
                                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                    <Navbar.Collapse id="responsive-navbar-nav">
                                        <Nav
                                            className="mx-auto "
                                            variant="pills"
                                        >
                                            <Nav.Link
                                                className={
                                                    (activeTab === 10
                                                        ? "bg-warning border border-warning rounded mr-5 mr-5"
                                                        : "mr-5") +
                                                    (userType == 1
                                                        ? " d-none"
                                                        : "")
                                                }
                                                onClick={() => setActiveTab(10)}
                                            ></Nav.Link>

                                            <Nav.Link
                                                as={Link}
                                                to="/gstBill"
                                                className={
                                                    (activeTab === 3
                                                        ? "bg-warning border border-warning rounded mr-5"
                                                        : "mr-5") +
                                                    (userType != 1
                                                        ? " d-none"
                                                        : "")
                                                }
                                                onClick={() => setActiveTab(3)}
                                            >
                                                GST Bill
                                            </Nav.Link>
                                            <Nav.Link
                                                as={Link}
                                                to="/nonGstBill"
                                                className={
                                                    (activeTab === 4
                                                        ? "bg-warning border border-warning rounded mr-5"
                                                        : "mr-5") +
                                                    (userType != 1
                                                        ? " d-none"
                                                        : "")
                                                }
                                                onClick={() => setActiveTab(4)}
                                            >
                                                Non GST Bill
                                            </Nav.Link>
                                            <Nav.Link
                                                as={Link}
                                                to="/expenses"
                                                className={
                                                    (activeTab === 5
                                                        ? "bg-warning border border-warning rounded mr-5"
                                                        : "mr-5") +
                                                    (userType != 1
                                                        ? " d-none"
                                                        : "")
                                                }
                                                onClick={() => setActiveTab(5)}
                                            >
                                                Expenses
                                            </Nav.Link>

                                            <Nav.Link
                                                as={Link}
                                                to="/products"
                                                className={
                                                    (activeTab === 7
                                                        ? "bg-warning border border-warning rounded mr-5"
                                                        : "mr-5") +
                                                    (userType != 1
                                                        ? " d-none"
                                                        : "")
                                                }
                                                onClick={() => setActiveTab(7)}
                                            >
                                                Products
                                            </Nav.Link>
                                            <Nav.Link
                                                as={Link}
                                                to="/PartyManager"
                                                className={
                                                    (activeTab === 8
                                                        ? "bg-warning border border-warning rounded mr-5"
                                                        : "mr-5") +
                                                    (userType != 1
                                                        ? " d-none"
                                                        : "")
                                                }
                                                onClick={() => setActiveTab(8)}
                                            >
                                                Parties
                                            </Nav.Link>
                                            <Nav.Link
                                                as={Link}
                                                to="/workers"
                                                className={
                                                    (activeTab === 9
                                                        ? "bg-warning border border-warning rounded mr-5"
                                                        : "mr-5") +
                                                    (userType != 1
                                                        ? " d-none"
                                                        : "")
                                                }
                                                onClick={() => setActiveTab(9)}
                                            >
                                                Workers
                                            </Nav.Link>
                                            <NavDropdown
                                                title="History"
                                                id="collasible-nav-dropdown"
                                                className={
                                                    (activeTab === 10
                                                        ? "bg-warning border border-warning rounded mr-5 h6"
                                                        : "h6") +
                                                    (userType == 3
                                                        ? " d-none"
                                                        : "")
                                                }
                                                onClick={() => setActiveTab(10)}
                                            >
                                                {/* <NavDropdown.Item
                                                    onClick={() =>
                                                        setActiveTab(10)
                                                    }
                                                    className={
                                                        userType != 1
                                                            ? " d-none"
                                                            : ""
                                                    }
                                                >
                                                    <Link
                                                        to="/quoteHistory"
                                                        className="mt-2 h6"
                                                        style={{
                                                            textDecoration:
                                                                "none",
                                                        }}
                                                    >
                                                        Quote History
                                                    </Link>
                                                </NavDropdown.Item> */}
                                                <NavDropdown.Item
                                                    onClick={() =>
                                                        setActiveTab(10)
                                                    }
                                                    className={
                                                        userType != 1
                                                            ? " d-none"
                                                            : ""
                                                    }
                                                >
                                                    <Link
                                                        to="/gstHistory"
                                                        className="mt-2 h6"
                                                        style={{
                                                            textDecoration:
                                                                "none",
                                                        }}
                                                    >
                                                        GST History
                                                    </Link>
                                                </NavDropdown.Item>
                                                <NavDropdown.Item
                                                    onClick={() =>
                                                        setActiveTab(10)
                                                    }
                                                    className={
                                                        userType != 1
                                                            ? " d-none"
                                                            : ""
                                                    }
                                                >
                                                    <Link
                                                        to="/nonGstHistory"
                                                        className="mt-2 h6"
                                                        style={{
                                                            textDecoration:
                                                                "none",
                                                        }}
                                                    >
                                                        Non GST History
                                                    </Link>
                                                </NavDropdown.Item>
                                            </NavDropdown>
                                            <Nav.Link>
                                                <Button className="ml-5">
                                                    <ExitToAppIcon
                                                        onClick={(e) => {
                                                            localStorage.clear();
                                                            refresh();
                                                        }}
                                                    />
                                                </Button>
                                            </Nav.Link>
                                        </Nav>
                                    </Navbar.Collapse>
                                </Container>
                            </Navbar>

                            <Routes>
                                <Route path="/" element={<GSTBill />} />
                                <Route path="/quote" element={<Quote />} />
                                <Route path="/gstBill" element={<GSTBill />} />
                                <Route
                                    path="/nonGstBill"
                                    element={<NONGSTBill />}
                                />
                                <Route
                                    path="/quoteHistory"
                                    element={<QuoteHistory />}
                                />
                                <Route
                                    path="/gstHistory"
                                    element={<GSTBillHistory />}
                                />
                                <Route
                                    path="/nonGstHistory"
                                    element={<NONGSTBillHistory />}
                                />
                                <Route
                                    path="/expenses"
                                    element={<ExpensesManager />}
                                />
                                <Route
                                    path="/ledgerManager/:partyId"
                                    element={<CLedgerManager />}
                                />
                                <Route
                                    path="/workers"
                                    element={<WorkerManager />}
                                />
                                <Route
                                    path="/presentyManager/:workerId"
                                    element={<CPresentyManager />}
                                />

                                <Route
                                    path="/products"
                                    element={
                                        <div className="">
                                            <div className="form my-5">
                                                <div className="  inputes2 mt-2 mb-4 ">
                                                    <TextField
                                                        id="outlined-basic"
                                                        // value={34}
                                                        size="small"
                                                        label="New Product Name"
                                                        className="inputField  px-2"
                                                        name="newProduct"
                                                        type="text"
                                                        style={{
                                                            width: "250px",
                                                        }}
                                                        variant="outlined"
                                                        value={currentProduct}
                                                        onChange={(e) => {
                                                            setCurrentProduct(
                                                                e.target.value
                                                            );
                                                            console.log(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />

                                                    <Autocomplete
                                                        id="free-solo-demo"
                                                        className="inputFieldp mt-2  mx-2"
                                                        // sx={{ width: 300 }}
                                                        options={
                                                            Unit != null
                                                                ? Unit
                                                                : []
                                                        }
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                // label="party name"
                                                                label="Unit"
                                                                variant="outlined"
                                                                size="small"
                                                                style={{
                                                                    marginTop:
                                                                        ".3em",
                                                                }}
                                                                type="text"
                                                                value={
                                                                    currentUnit
                                                                }
                                                            />
                                                        )}
                                                        onChange={(
                                                            event,
                                                            value
                                                        ) => {
                                                            if (value != null) {
                                                                setCurrentUnit(
                                                                    value ==
                                                                        "sq.ft"
                                                                        ? 1
                                                                        : 4
                                                                );
                                                                console.log(
                                                                    value
                                                                );
                                                            }
                                                        }}
                                                    />
                                                    <TextField
                                                        id="outlined-basic"
                                                        // value={34}
                                                        size="small"
                                                        label="Quantity"
                                                        className="inputField  px-2"
                                                        name="quantity"
                                                        type="number"
                                                        variant="outlined"
                                                        // style={{ width: "100px" }}
                                                        value={currentQuantity}
                                                        onChange={(e) => {
                                                            setCurrentQuantity(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />

                                                    <TextField
                                                        id="outlined-basic"
                                                        // value={34}
                                                        size="small"
                                                        label="Unit Price"
                                                        className="inputField  px-2"
                                                        name="unitPrice"
                                                        type="number"
                                                        variant="outlined"
                                                        value={currentUnitPrice}
                                                        onChange={(e) => {
                                                            setCurrentUnitPrice(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />

                                                    <Button
                                                        variant="contained"
                                                        className="AddButton mt-4"
                                                        onClick={
                                                            insertNewProduct
                                                        }
                                                    >
                                                        Add
                                                    </Button>
                                                </div>
                                            </div>
                                            <hr />
                                            <div>
                                                <table
                                                    id="productTable"
                                                    className="display"
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                >
                                                    <tr>
                                                        <th>id</th>
                                                        <th>Product</th>
                                                        <th>Unit</th>
                                                        <th>quantity</th>
                                                        <th>Rate</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>

                                                    {products &&
                                                        products.map(
                                                            (item, id) => {
                                                                return (
                                                                    <tr
                                                                        key={id}
                                                                    >
                                                                        <td>
                                                                            {
                                                                                item.id
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                item.label
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {item.unit ==
                                                                            1
                                                                                ? "sq.ft"
                                                                                : "Unit"}
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                item.quantity
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                item.price
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            1
                                                                        </td>
                                                                        <td>
                                                                            <span
                                                                                onClick={() =>
                                                                                    handleShow(
                                                                                        item.quantity,
                                                                                        item.id,
                                                                                        item.price
                                                                                    )
                                                                                }
                                                                            >
                                                                                <EditIcon
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                    }}
                                                                                    color="primary"
                                                                                />
                                                                            </span>

                                                                            <span
                                                                                onClick={() =>
                                                                                    deleteProduct(
                                                                                        item.id
                                                                                    )
                                                                                }
                                                                            >
                                                                                <DeleteIcon
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                    }}
                                                                                    color="error"
                                                                                />
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            }
                                                        )}
                                                </table>
                                            </div>

                                            <Modal
                                                show={show}
                                                onHide={() => {
                                                    setShow(false);
                                                }}
                                            >
                                                <Modal.Header closeButton>
                                                    <Modal.Title>
                                                        Update Quantity
                                                    </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <div class="row">
                                                        <TextField
                                                            id="outlined-basic"
                                                            // value={34}
                                                            size="small"
                                                            label="Quantity"
                                                            className="inputField  px-2 col-6"
                                                            name="quantity"
                                                            type="number"
                                                            variant="outlined"
                                                            // style={{ width: "100px" }}
                                                            value={
                                                                currentQuantity
                                                            }
                                                            onChange={(e) => {
                                                                setCurrentQuantity(
                                                                    e.target
                                                                        .value
                                                                );
                                                            }}
                                                        />

                                                        <TextField
                                                            id="outlined-basic"
                                                            // value={34}
                                                            size="small"
                                                            label="Price"
                                                            className="inputField  px-2 col-6"
                                                            name="price"
                                                            type="number"
                                                            variant="outlined"
                                                            // style={{ width: "100px" }}
                                                            value={
                                                                currentUnitPrice
                                                            }
                                                            onChange={(e) => {
                                                                setCurrentUnitPrice(
                                                                    e.target
                                                                        .value
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button
                                                        variant="secondary"
                                                        onClick={() => {
                                                            setShow(false);
                                                        }}
                                                    >
                                                        Close
                                                    </Button>
                                                    <Button
                                                        class="btn btn-primary"
                                                        onClick={() =>
                                                            submitProductUpdate(
                                                                {
                                                                    currentQuantity,
                                                                }
                                                            )
                                                        }
                                                    >
                                                        Save Changes
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                        </div>
                                    }
                                />
                                <Route
                                    path="/PartyManager"
                                    element={<PartyManager />}
                                />
                            </Routes>
                        </div>
                    )}
                </HashRouter>
                {!isLoggedIn && <Login />}
            </AuthContext.Provider>
            <ToastContainer />
        </div>
    );
}

import React, { useEffect, useState, useContext } from "react";
import { Autocomplete, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import image from "../../images/image.png";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { API_URL } from "../../Global";
import axios from "axios";
import { toast } from "react-toastify";
import { FilterContext } from "./../../App";
import "../../App.css";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const Quote = (props) => {
    const { currentType, currentCompany } = useContext(FilterContext);

    // Form data
    const [formData, setFormData] = useState("");

    const [quotId, setQuotId] = useState(null);
    const [items, setItems] = useState([]);
    const [products, setProducts] = useState(null);

    const [saveFlag, setSaveFlag] = useState(false);
    const [grandTotal, setGrandTotal] = useState(0);
    const [currentPrice, setCurrentPrice] = useState(0);
    // const [currentType, setCurrentType] = useState(filterContext.currentType);
    // const [currentCompany, setCurrentCompany] = useState(props.currentCompany);

    const [currentProduct, setCurrentProduct] = useState("");
    const [currentUnit, setCurrentUnit] = useState("Sq.ft.");
    const [currentUnitPrice, setCurrentUnitPrice] = useState(0);
    const [currentWidth, setCurrentWidth] = useState(1);
    const [currentHeight, setCurrentHeight] = useState(1);
    const [currentSqft, setCurrentSqft] = useState(1);
    const [currentQuantity, setCurrentQuantity] = useState(1);
    const [printComponentRef, setPrintComponentRef] = useState(null);

    const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));

    // dueDate: ,
    // console.log(formData);

    // setDate(moment(new Date()).format("YYYY-MM-DD"))

    const Unit = [{ label: "Sq.ft." }, { label: "Inch" }, { label: "MM" }];

    // delete
    const deleteItem = (id) => {
        console.log("clicked" + id);

        setItems((oldItems) => {
            return oldItems.filter((arrElem, index) => {
                if (index == id) {
                    setGrandTotal(Number(grandTotal) - Number(arrElem.price));
                }
                return index !== id;
            });
        });
    };

    const listOfItems = () => {
        // update grand total
        setGrandTotal(Number(grandTotal) + Number(currentPrice));

        let multiplier =
            currentUnit === "Sq.ft." ? 1 : currentUnit === "Inch" ? 12 : 305;

        setItems((oldData) => {
            return [
                ...oldData,
                {
                    product: currentProduct,
                    unit: currentUnit,
                    unitPrice: currentUnitPrice,
                    quantity: currentQuantity,
                    sqft: currentSqft,
                    price: currentPrice,
                },
            ];
        });
        // setFormData('');
    };

    const updateCurrentPrice = (type, value) => {
        let divisor =
            currentUnit === "Sq.ft." ? 1 : currentUnit === "Inch" ? 144 : 92903;

        if (type === "unit") {
            setCurrentUnit(value);
            let divisor =
                value === "Sq.ft." ? 1 : value === "Inch" ? 144 : 92903;
            setCurrentPrice(
                (
                    (currentWidth *
                        currentHeight *
                        currentUnitPrice *
                        currentQuantity) /
                    divisor
                ).toFixed(2)
            );
        } else if (type === "unitPrice") {
            setCurrentUnitPrice(value);
            setCurrentPrice(
                (
                    (currentWidth * currentHeight * value * currentQuantity) /
                    divisor
                ).toFixed(2)
            );
        } else if (type === "quantity") {
            setCurrentQuantity(value);
            setCurrentPrice(
                (
                    (currentWidth * currentHeight * currentUnitPrice * value) /
                    divisor
                ).toFixed(2)
            );
        } else if (type === "product") {
            setCurrentUnitPrice(value);
            setCurrentPrice(
                (
                    (currentWidth * currentHeight * value * currentQuantity) /
                    divisor
                ).toFixed(2)
            );
        } else if (type === "width") {
            setCurrentWidth(value);

            setCurrentPrice(
                (
                    (value *
                        currentHeight *
                        currentUnitPrice *
                        currentQuantity) /
                    divisor
                ).toFixed(2)
            );
        } else if (type === "height") {
            setCurrentHeight(value);
            setCurrentPrice(
                (
                    (currentWidth *
                        value *
                        currentUnitPrice *
                        currentQuantity) /
                    divisor
                ).toFixed(2)
            );
        }
        updateSqft(type, value);
    };

    const updateSqft = (type, value) => {
        let multiplier =
            currentUnit === "Sq.ft." ? 1 : currentUnit === "Inch" ? 144 : 92903;
        if (type === "width") {
            setCurrentSqft(
                (
                    ((value * currentHeight) / multiplier) *
                    currentQuantity
                ).toFixed(2)
            );
        } else if (type === "height") {
            setCurrentSqft(
                (
                    ((currentWidth * value) / multiplier) *
                    currentQuantity
                ).toFixed(2)
            );
        } else if (type === "unit") {
            let multiplier =
                value === "Sq.ft." ? 1 : value === "Inch" ? 144 : 92903;
            setCurrentSqft(
                (
                    ((currentWidth * currentHeight) / multiplier) *
                    currentQuantity
                ).toFixed(2)
            );
        } else if (type === "quantity") {
            setCurrentSqft(
                (((currentWidth * currentHeight) / multiplier) * value).toFixed(
                    2
                )
            );
        }
    };

    // fetching data
    const fetchProductList = () => {
        let url = API_URL;
        // const query = `SELECT CONCAT(id, ', ', name) AS name, address FROM party;`;
        const query = `select id, name as label, price from products where status = 1 and customerType = ${currentType} and company = ${currentCompany}`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                setProducts(res.data);
            })
            .catch((err) => {
                console.log("products fetch error: ", err);
            });
    };
    useEffect(() => {
        fetchProductList();
    }, [currentType, currentCompany]);

    // insert bill list
    const insertQuotList = (quotId) => {
        let url = API_URL;

        // 1.  insert into deliveryMemoList
        items.map((item, index) => {
            const query = `INSERT INTO billList(billType, billId, particular, quantity, rate, sqft, amount) VALUES(
          3,
          ${quotId},
          '${item.product}', 
          ${item.quantity}, 
          ${item.unitPrice}, 
          ${item.sqft},
          ${item.price}
        )`;
            let data = { crossDomain: true, crossOrigin: true, query: query };
            axios
                .post(url, data)
                .then((res) => {
                    console.log("insertBillList successfull, index: ", index);
                })
                .catch((err) => {
                    console.log("failed to insertBillList, error: ", err);
                });
        });
    };

    // save QUOT
    const saveQuot = () => {
        const newDate = moment(new Date()).format("YYYY-MM-DD");
        let query;

        query = `INSERT INTO quotation (quotNumber, partyName, mobileNo, address, date, total) values(
            "${moment(new Date()).format("MMMYY/DD/hmmss").toUpperCase()}",
                "${formData.name}",
           "${formData.mobileNo}",
           "${formData.address}",
           "${newDate}",
           ${grandTotal}
            )`;

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(API_URL, data)
            .then((res) => {
                toast.success("Quot. generated successfully");
                setQuotId(res.data.insertId);
                insertQuotList(res.data.insertId);
            })
            .catch((err) => {
                toast.error("Failed to Generate Quot ");
            });
    };

    return (
        <div className="">
            <div className="form mt-4 mb-0">
                <div className="inputesC1 mb-2">
                    <TextField
                        id="outlined-basic"
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                name: e.target.value,
                            })
                        }
                        required="true"
                        size="small"
                        label="Name"
                        className="inputField "
                        name="Name"
                        type="text"
                        variant="outlined"
                    />

                    <TextField
                        id="standard-basic"
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                address: e.target.value,
                            })
                        }
                        required="true"
                        size="small"
                        label="Address"
                        className="inputField  mx-2"
                        name="address"
                        type="text"
                        variant="standard"
                    />

                    <TextField
                        id="outlined-basic"
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                mobileNo: e.target.value,
                            })
                        }
                        required="true"
                        size="small"
                        label="Mobile No."
                        type="tel"
                        className="inputField  mx-2"
                        name="MObile No."
                        variant="outlined"
                    />
                    <TextField
                        id="outlined-basic"
                        format="MM/DD/YYY"
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                date: e.target.value,
                            })
                        }
                        size="small"
                        type="date"
                        value={formData.date}
                        // options={products != null ? products : []}
                        views="                     "
                        className="inputField  mx-2"
                        name="Date"
                        variant="outlined"
                    />
                    {/* <TextField
                    id="Date"
                    label="Date"
                    // variant="outlined"
                    className="mr-2"
                    // value={this.state.dueDate}
                    // onChange={(e) => this.setState({ dueDate: e.target.value })}
                    // required="true"
                    size="small"
                    type="date"
                    // style={{ width: "180px" }}
                /> */}
                </div>
                <hr />
                {/* <div>
                    <button className="btn btn-sm btn-danger px-5 pt-2">
                        <h6>{["Mediator", "Retailer"][currentType - 1]}</h6>
                    </button>
                </div> */}
                <div className="  inputes2 mt-2 mb-4 ">
                    <Autocomplete
                        id="free-solo-demo"
                        className="inputFieldp      "
                        
                        options={products != null ? products : []}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                // label="party name"
                                label="Product"
                                variant="outlined"
                                size="small"
                            />
                        )}
                        onChange={(event, value) => {
                            if (value != null) {
                                console.log(value.price);
                                setCurrentProduct(value.label);
                                updateCurrentPrice("product", value.price);
                            }
                        }}
                    />
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={Unit}
                        className="inputField  mx-2"
                        size="small"
                        sx={{ width: 10 }}
                        // style={{ width: "150px" }}
                        renderInput={(params) => (
                            <TextField {...params} label="Unit" />
                        )}
                        defaultValue="Sq.ft."
                        onChange={(event, value) => {
                            if (value != null) {
                                updateCurrentPrice("unit", value.label);
                            }
                        }}
                    />

                    <TextField
                        id="unitPrice"
                        label="Unit Price / sq.ft."
                        variant="outlined"
                        size="small"
                        className="inputField  px-2"
                        sx={{ width: 150 }}
                        // md={{ width: 100 }}
                        
                        // style={{ width: "130px" }}
                        value={currentUnitPrice}
                    />
                    <TextField
                        id="outlined-basic"
                        // value={34}
                        size="small"
                        label="Width"
                        className="inputField  px-2"
                        name="width"
                        type="number"
                        variant="outlined"
                        // style={{ width: "100px" }}
                        value={currentWidth}
                        onChange={(e) => {
                            updateCurrentPrice("width", e.target.value);
                        }}
                    />
                    <TextField
                        id="outlined-basic"
                        // value={34}
                        size="small"
                        label="Height"
                        className="inputField  px-2"
                        name="height"
                        type="number"
                        variant="outlined"
                        // style={{ width: "100px" }}
                        value={currentHeight}
                        onChange={(e) => {
                            updateCurrentPrice("height", e.target.value);
                        }}
                    />
                    <TextField
                        id="outlined-basic"
                        // value={34}
                        size="small"
                        label="Quantity"
                        className="inputField  px-2"
                        name="quantity"
                        type="number"
                        variant="outlined"
                        // style={{ width: "100px" }}
                        value={currentQuantity}
                        onChange={(e) => {
                            updateCurrentPrice("quantity", e.target.value);
                        }}
                    />

                    <TextField
                        id="sqft"
                        size="small"
                        label="Sq.ft."
                        className="inputField  px-2"
                        name="sqft"
                        variant="outlined"
                        // style={{ width: "150px" }}
                        value={currentSqft}
                        readOnly
                    />

                    <TextField
                        id="price"
                        size="small"
                        label="Price"
                        className="inputField  px-2"
                        name="Price"
                        type="number"
                        variant="outlined"
                        // style={{ width: "150px" }}
                        value={currentPrice}
                        onChange={(e) => {
                            setCurrentPrice(e.target.value);
                        }}
                    />

                    <Button
                        variant="contained"
                        className="AddButtons "
                        onClick={listOfItems}
                        disabled={
                            !formData.address ||
                            !formData.name ||
                            !formData.mobileNo
                        }
                    >
                        Add
                    </Button>
                </div>
            </div>

            {/* Overview */}

            <div
                className="ReceptContainer"
                ref={(el) => setPrintComponentRef(el)}
            >
                <div className="text-center">
                <div className="header  ReceptBg ">
                            <div class="row logoContainer ">
                                <div class="col-sm-4  col-md-4 text-start" style={{ width: '15.666%' }}>
                                    <img
                                        src={image}
                                        alt="logo"
                                        className="img-fluid"
                                    />
                                </div>

                                <div class="col-sm-8  col-md-8">
                                    <p className="py-3 pl-4 text-center">
                                        <h1><b>Ajit Arts</b></h1><br />
                                        <p>Near H.P.College,Mayani Road,VITA,Dist.Sangali.Maharashtra<br />
                                            GSTIN : 27AOCPP1872P1ZC</p><br />
                                        Prop.Mr. Ajit Shamrao Patil<br />
                                        Mob. 9822441697<br />
                                        PIN : 415311<br />
                                        Pan No. AOCPP1872p

                                    </p>
                                </div>

                            </div>
                        </div>
                        {/* <h5 className="bg-secondary text-white px-2">
                            TAX INVOICE
                        </h5> */}

                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table"  style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid',backgroundColor:'black' }}>
                                <TableHead style={{color:'white'}}>
                                    <TableRow >
                                        <TableCell style={{color:'white'}}>DIGITAL PRINTING</TableCell>
                                        <TableCell style={{color:'white'}}>LED SIGN BOARD</TableCell>
                                        <TableCell style={{color:'white'}}>RADIUM CUTTING</TableCell>
                                        <TableCell style={{color:'white'}}>LASER CUTTING</TableCell>
                                        <TableCell style={{color:'white'}}>CNC ROUTER</TableCell>
                                        <TableCell style={{color:'white'}}>CHANNEL BENGING</TableCell>
                                        <TableCell style={{color:'white'}}>TROPHY HOUSE</TableCell>
                                        <TableCell style={{color:'white'}}>ART GALLERY</TableCell>
                                        <TableCell style={{color:'white'}}>GLOW SIGN BOARD</TableCell>
                                        <TableCell style={{color:'white'}}>MATERIAL</TableCell>

                                    </TableRow>
                                </TableHead>
                            </Table>
                        </TableContainer>

                        {/* <h6 className="bg-secondary text-secondary px-2">.</h6> */}

                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={7} align="left">
                                    <div class="details">
                                        <p>Name : </p>
                                        <p>Address : </p>
                                        <p>GST TIN NO. :</p>
                                        <p>state : Maharashtra</p>
                                        <p>Pan No : </p>

                                        <p align="right" style={{ marginRight: '5em' }}>state code: </p>
                                    </div>
                                </Grid>
                                <div class="verticalLine"></div>
                                <Grid item xs={4} align="left">
                                    <div class="invoiceDetails">
                                        <p>Invoice NO : 383</p>
                                        <p>Invoice Date : </p>
                                    </div>
                                </Grid>

                            </Grid>
                        </Box>
                    <h6 className="bg-secondary text-secondary px-2">.</h6>

                    <table>
                        <tr className="titles" >
                            <th>Sr. No.</th>
                            <th>Product</th>
                            <th>Quantity</th>
                            <th>Rate / sq.ft.</th>
                            <th>Sq. ft.</th>
                            <th>Unit</th>

                            <th>Total</th>
                            <th className="d-print-none">Action</th>
                        </tr>

                        {items.map((item, id) => {
                            return (
                                <tr key={id}>
                                    <td>{id + 1}</td>
                                    <td>{item.product}</td>
                                    <td>{item.quantity}</td>
                                    <td>
                                        {(item.price / item.sqft).toFixed(2)}
                                    </td>
                                    <td>{item.sqft}</td>
                                    <td>Sq. ft.</td>

                                    <td>{Number(item.price).toFixed(2)}</td>
                                    <td className="d-print-none">
                                        <span onClick={() => deleteItem(id)}>
                                            {" "}
                                            <DeleteIcon color="error" />{" "}
                                        </span>
                                    </td>
                                </tr>
                            );
                        })}

                        <br />
                        <tr></tr>
                        <tr>
                            <td colSpan={6}>
                                <b>Grand Total</b>
                            </td>
                            <td>
                                <b>{grandTotal.toFixed(2)}</b>
                            </td>
                        </tr>
                        <div className="Qrcode">
                            <img
                                src="/Assets/QrCode1.jpg"
                                className="qrimg"
                                alt="QrCode"
                            />
                        </div>
                        {/* <tr>
                            <td>
                              <h1>hey</h1>
                            </td>
                        </tr> */}
                    </table>
                </div>
            </div>

            {/* invoice */}

            <ReactToPrint content={() => printComponentRef}>
                <PrintContextConsumer>
                    {({ handlePrint }) => (
                        <Button
                            onClick={handlePrint}
                            className="mb-5 mt-0 mr-3"
                            color="primary"
                            variant="contained"
                            style={{ float: "right" }}
                            disabled={saveFlag == true ? false : true}
                        >
                            PRINT
                        </Button>
                    )}
                </PrintContextConsumer>
            </ReactToPrint>
            {/* save Quot */}
            <Button
                className=" mr-3"
                color="secondary"
                variant="contained"
                style={{ float: "right", marginRight: "20px" }}
                // type="submit"
                onClick={(e) => {
                    setSaveFlag(true);
                    saveQuot();
                }}
                disabled={
                    !formData.address || !formData.name || !formData.mobileNo
                }
            >
                SAVE QUOT
            </Button>
        </div>
    );
};

export default Quote;

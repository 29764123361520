import React, { Component } from "react";
import { toast } from "react-toastify";
// datatable
import "jquery/dist/jquery.min.js";

import "datatables.net-dt/js/dataTables.dataTables";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import Button from "@mui/material/Button";

import { Col, Modal, Row } from "react-bootstrap";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from "@mui/icons-material/Preview";

import image from "../../images/image.png";


//API handling components
import { API_URL } from "../../Global";

// datatable
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

const axios = require("axios");

export class QuoteHistory extends Component {
    constructor(props) {
        super();

        this.state = {
            showUpdateModel: false,
            currentQuotId: null,
            quotList: null,
            itemsList: null,
            printComponentRef: null,
        };
    }

    fetchQuotList = () => {
        let url = API_URL;
        const query = `SELECT * FROM quotation as q where q.status=1 ORDER BY q.id DESC;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("bill: ", res.data);
                this.setState({ quotList: res.data });

                // init data table
                this.initializeDataTable();
            })
            .catch((err) => {
                console.log("Quot list fetch error: ", err);
            });
    };

    fetchQuotItemList = () => {
        let url = API_URL;
        const query = `SELECT * FROM billList where billId = ${this.state.currentQuotId} and billType=3;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("bill list data: ", res.data);
                this.setState({ itemsList: res.data });

                // init data table
                this.initializeDataTable();
            })
            .catch((err) => {
                console.log("bill list fetch error: ", err);
            });
    };

    deleteRecord(id) {
        let url = API_URL;
        const query = `UPDATE quotation SET status = 0  WHERE id=${id};`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("deleted status data: ", res.data);
                toast.success("Record deleted successfully");
                this.fetchQuotList();
                window.location.reload();

            })
            .catch((err) => {
                console.log("record delete error: ", err);
                toast.error("Failed to delete record");
            });
    }

    initializeDataTable() {
        $("#quotHistoryTable")
            .DataTable({
                destroy: true,
                dom:
                    "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
                    "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
                    "<'row'<'col-sm-12' tr>>" +
                    "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
                responsive: true,
                lengthChange: false,
                autoWidth: false,
                buttons: ["csv", "print"],
                order: [[0, "desc"]],
            })
            .buttons()
            .container()
            .appendTo("#sitesTable_wrapper .col-md-6:eq(0)");
    }

    componentDidMount() {
        this.fetchQuotList();
    }

    renderQuotList = () => {
        if (this.state.quotList == null) return null;

        // else
        return this.state.quotList.map((quot) => {
            return (
                <tr align="center">
                    <td>{quot.id}</td>
                    <td>{quot.partyName}</td>
                    <td>{quot.address}</td>
                    <td>{quot.mobileNo}</td>
                    <td>{quot.total}</td>
                    <td>{moment(quot.date).format("DD/MM/YYYY")}</td>
                    <td className="d-flex justify-content-center">
                        &nbsp;
                        <PreviewIcon
                            onClick={() => {
                                this.setState(
                                    {
                                        showUpdateModel: true,
                                        currentQuotId: quot.id,
                                        quotNumber: quot.quotNumber,
                                        name: quot.partyName,
                                        address: quot.address,
                                        mobileNo: quot.mobileNo,
                                        total: quot.total,
                                        date: quot.date,
                                    },
                                    this.fetchQuotItemList
                                );
                            }}
                            color="success"
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <DeleteIcon
                            onClick={(e) => {
                              var pin = window.prompt("Delete the item?")
                                if ( pin == 123){
                                    this.deleteRecord(quot.id);
                                    toast.success("Deleted succesfully");
                                }
                                else {
                                    toast.error("Entered wrong pin");
                                }
                            }}
                            color="error"
                        />
                    </td>
                </tr>
            );
        });
    };

    render() {
        return (
            <div className="">
                <Row>
                    <Col
                        md="12"
                        className="m-0 p-1 measure1 table-responsive-md"
                        style={{ minHeight: "85vh" }}
                    >
                        <div>
                            <table
                                id="quotHistoryTable"
                                style={{ width: "100%" }}

                            >
                                <thead>
                                    <tr align="center">
                                        <th align="center">Quot Ids</th>
                                        <th align="center">Name</th>
                                        <th align="center">Address</th>
                                        <th align="center">Mobile No.</th>
                                        <th align="center">Total</th>
                                        <th align="center">Date</th>
                                        <th align="center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>{this.renderQuotList()}</tbody>
                            </table>
                            <Modal
                                onHide={(e) =>
                                    this.setState({ showUpdateModel: false })
                                }
                                size="lg"
                                show={this.state.showUpdateModel}
                                // onHide={() => setLgShow(false)}
                                aria-labelledby="example-modal-sizes-title-lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Bill Details</Modal.Title>
                                </Modal.Header>
                                <Row>
                                    {/* Overview */}

                                    <div
                                        className="ReceptContainerss"
                                        //   ref={(el) => setPrintComponentRef(el)}
                                        ref={(el) =>
                                            (this.printComponentRef = el)
                                        }
                                    >
                                        <div className="text-center">
                                            <div className="header  ReceptBg ">
                                                <div class="logoContainer ">
                                                    <div class="col-sm-3  col-md-3 text-start" style={{ width: '20.666%' }}>
                                                        <img
                                                            src={image}
                                                            alt="logo"
                                                            className="img-fluid"
                                                        />
                                                    </div>

                                                    <div class="col-sm-9  col-md-9">
                                                        <p className="py-3 pl-4 text-center">
                                                            <h1><b>Ajit Arts</b></h1><br />
                                                            <p>Near H.P.College, Mayani Road, VITA, Dist.Sangali, Maharashtra<br />
                                                                GSTIN : 27AOCPP1872P1ZC &nbsp;&nbsp;&nbsp; PIN : 415311</p><br />
                                                            <p style={{ marginTop: '-2em' }}>
                                                                Prop.Mr. Ajit Shamrao Patil<br />
                                                                Mob. 9822441697 &nbsp;&nbsp;&nbsp;  Pan No: AOCPP1872P
                                                            </p>

                                                        </p>
                                                    </div>

                                                </div>
                                            </div>
                                            <h5 className="bg-secondary text-white px-2">
                                                QUOTATION
                                            </h5>

                                            <div className="p-2 text-start">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <p className="text-start">
                                                            Party Name: &nbsp;{" "}
                                                            {this.state.name}
                                                        </p>
                                                    </div>
                                                    <div className="col-6">
                                                        <p>
                                                            {" "}
                                                            Phone No: &nbsp;
                                                            {
                                                                this.state
                                                                    .mobileNo
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <p>
                                                            Address: &nbsp;{" "}
                                                            {this.state.address}
                                                        </p>
                                                    </div>
                                                    <div className="col-6">
                                                        <p>
                                                            Date :
                                                            <b>
                                                                {moment(
                                                                    new Date()
                                                                ).format(
                                                                    "D/M/YYYY"
                                                                )}
                                                            </b>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <p>
                                                            Quot Number : &nbsp;{" "}
                                                            {
                                                                this.state
                                                                    .quotNumber
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>


                                            <h6 className="bg-secondary text-secondary px-2">
                                                .
                                            </h6>

                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Sr. No.</th>
                                                        <th>Product</th>
                                                        <th>Quantity</th>
                                                        <th>Sq. ft.</th>
                                                        <th>Unit</th>
                                                        <th>Rate / sq.ft.</th>
                                                        <th>Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.itemsList &&
                                                        this.state.itemsList.map(
                                                            (item, id) => {
                                                                return (
                                                                    <tr
                                                                        key={id}
                                                                    >
                                                                        <td>
                                                                            {id +
                                                                                1}
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                item.particular
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                item.quantity
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                item.sqft
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            Sq.
                                                                            ft.
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                item.rate
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {Number(
                                                                                item.amount
                                                                            ).toFixed(
                                                                                2
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            }
                                                        )}
                                                    <tr>
                                                        <td colSpan={6}>
                                                            <b>Grand Total</b>
                                                        </td>
                                                        <td>
                                                            <b>
                                                                {this.state
                                                                    .total &&
                                                                    this.state.total.toFixed(
                                                                        2
                                                                    )}
                                                            </b>
                                                        </td>
                                                    </tr>
                                                    {/* <div className="Qrcode">
                                                        <img
                                                            src="/Assets/QrCode1.jpg"
                                                            className="qrimg"
                                                            alt="QrCode"
                                                        />
                                                    </div> */}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="">
                                        <ReactToPrint
                                            content={() =>
                                                this.printComponentRef
                                            }
                                        >
                                            <PrintContextConsumer>
                                                {({ handlePrint }) => (
                                                    <Button
                                                        onClick={handlePrint}
                                                        className="mb-5 mt-3 mr-3"
                                                        color="primary"
                                                        variant="contained"
                                                        style={{
                                                            float: "right",
                                                            marginRight: "40px",
                                                        }}
                                                    // disabled={saveFlag == true ? false : true}
                                                    // disabled={
                                                    //         (this.state.particular ||
                                                    //             this.state.quantity)
                                                    //             ? false
                                                    //             : true
                                                    //     }
                                                    >
                                                        PRINT
                                                    </Button>
                                                )}
                                            </PrintContextConsumer>
                                        </ReactToPrint>
                                    </div>
                                    {/* invoice */}
                                </Row>
                            </Modal>

                            {/* {this.renderUpdateModal()}
                            {this.renderDisplayBillModal()} */}
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default QuoteHistory;

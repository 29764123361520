import Button from "@mui/material/Button";
import { Modal } from "react-bootstrap";

import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Autocomplete, TextField } from "@mui/material";
import image from "../../images/image.png";
import moment from "moment";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import "./Style.css";
import "../../../src/App.css";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { API_URL } from "../../Global";
import { FilterContext } from "./../../App";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { FormControl } from "react-bootstrap";
import { Row, Col, Button as Btn1, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPenAlt,
    faBook,
    faTrash,
    faSyncAlt,
    faCalendarCheck,
} from "@fortawesome/free-solid-svg-icons";

const GSTBill = (props) => {
    const Unit = ["sq.ft", "unit"];
    const [formData, setFormData] = useState("");
    const [items, setItems] = useState([]);
    const [products, setProducts] = useState(null);
    const [parties, setParties] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false);
    const [activePartyName, setActivePartyName] = useState("");
    const [activePartyMobile, setActivePartyMobile] = useState("N/A");
    const [activePartyAddress, setActivePartyAddress] = useState("N/A");

    const [priceMode, setpriceMode] = useState(1);
    const [priceModeName, setpriceModeName] = useState("");

    const [billId, setBillId] = useState(null);
    const [currentProduct, setCurrentProduct] = useState("");
    const[description, setDescription] = useState("");
    const [currentUnit, setCurrentUnit] = useState("Sq.ft.");
    const [currentUnitPrice, setCurrentUnitPrice] = useState(0);
    const [currentWidth, setCurrentWidth] = useState(1);
    const [currentHeight, setCurrentHeight] = useState(1);
    const [currentSqft, setCurrentSqft] = useState(1);
    const [currentQuantity, setCurrentQuantity] = useState(1);
    const [currentPrice, setCurrentPrice] = useState(0);
    const [netAmount, setNetAmount] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const [id, setId] = useState(0);
    // const [partyName, setPartyName] = useState(0)
    const [currentparty, setCurrentParty] = useState("");
    const [partyId, setPartyId] = useState(0);
    const [newPartyName, setNewPartyname] = useState(null);
    const [newId, setNewId] = useState();

    const [printComponentRef, setPrintComponentRef] = useState(null);

    const [saveFlag, setSaveFlag] = useState(false);

    const PaymentMode = [
        { label: "Cash", name: "cash", value: "1" },
        { label: "cheque", name: "cheque", value: "2" },
        { label: "Online", name: "Online", value: "3" },
    ];

    const setPartyDetails = (id) => {
        id = Number(id);
        console.log("pid", id);
        let party = parties.filter((item) => item.id === id);
        console.log("party", party);
        setActivePartyMobile(party[0].mobile);
        setActivePartyAddress(party[0].address);
    };
    // delete
    const deleteItem = (id) => {
        console.log("clicked" + id);

        setItems((oldItems) => {
            return oldItems.filter((arrElem, index) => {
                if (index == id) {
                    setNetAmount(Number(netAmount) - Number(arrElem.price));
                }
                return index !== id;
            });
        });
    };

    const listOfItems = () => {
        // update grand total
        setNetAmount(Number(netAmount) + Number(currentPrice));

        setItems((oldData) => {
            return [
                ...oldData,
                {
                    party: currentparty,
                    product: currentProduct,
                    description: description,
                    unit: currentUnit === 1 ? "Sq.ft." : "unit",
                    unitPrice: currentUnitPrice,
                    quantity: currentQuantity,
                    sqft: currentSqft,
                    price: currentPrice,
                    id: id,
                },
            ];
        });
    };

    const updateCurrentPrice = (type, value) => {
        let divisor = 1;

        if (type === "unit") {
            setCurrentUnit(value);
            let divisor =
                value === "Sq.ft." ? 1 : value === "Inch" ? 144 : 92903;
            setCurrentPrice(
                (
                    (currentWidth *
                        currentHeight *
                        currentUnitPrice *
                        currentQuantity) /
                    divisor
                ).toFixed(2)
            );
        } else if (type === "unitPrice") {
            setCurrentUnitPrice(value);
            setCurrentPrice(
                (
                    (currentWidth * currentHeight * value * currentQuantity) /
                    divisor
                ).toFixed(2)
            );
        } else if (type === "quantity") {
            setCurrentQuantity(value);
            setCurrentPrice(
                (
                    (currentWidth * currentHeight * currentUnitPrice * value) /
                    divisor
                ).toFixed(2)
            );
        } else if (type === "product") {
            setCurrentUnitPrice(value);
            setCurrentPrice(
                (
                    (currentWidth * currentHeight * value * currentQuantity) /
                    divisor
                ).toFixed(2)
            );
        } else if (type === "width") {
            setCurrentWidth(value);

            setCurrentPrice(
                (
                    (value *
                        currentHeight *
                        currentUnitPrice *
                        currentQuantity) /
                    divisor
                ).toFixed(2)
            );
        } else if (type === "height") {
            setCurrentHeight(value);
            setCurrentPrice(
                (
                    (currentWidth *
                        value *
                        currentUnitPrice *
                        currentQuantity) /
                    divisor
                ).toFixed(2)
            );
        }
        updateSqft(type, value);
    };

    const updateSqft = (type, value) => {
        let multiplier = 1;
        if (type === "width") {
            setCurrentSqft(
                (
                    ((value * currentHeight) / multiplier) *
                    currentQuantity
                ).toFixed(2)
            );
        } else if (type === "height") {
            setCurrentSqft(
                (
                    ((currentWidth * value) / multiplier) *
                    currentQuantity
                ).toFixed(2)
            );
        } else if (type === "quantity") {
            setCurrentSqft(
                (((currentWidth * currentHeight) / multiplier) * value).toFixed(
                    2
                )
            );
        }
    };
    const updateQuantity = (quantity, id) => {
        let query;

        quantity = Math.ceil(quantity);
        query = `update products set quantity = quantity - ${quantity} where id = ${id}`;
        console.log(query);

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(API_URL, data)
            .then((res) => {
                // console.log("quantity updated successfully");
                // setBillId(res.data.insertId);
                // insertBillList(res.data.insertId);
            })
            .catch((err) => {
                toast.error("Failed to Load Quantity ");
            });
    };

    // insert bill list
    const insertBillList = (billId) => {
        let url = API_URL;

        // 1.  insert into deliveryMemoList
        items.map((item, index) => {
            const query = `INSERT INTO billList(billType, billId, particular,description, quantity, rate, sqft, amount) VALUES(
          1,
          ${billId},
          '${item.product}', 
          '${description}',
          ${item.quantity}, 
          ${item.unitPrice}, 
          ${item.sqft},
          ${item.price}
        )`;
            console.log(query);
            let data = { crossDomain: true, crossOrigin: true, query: query };
            axios
                .post(url, data)
                .then((res) => {
                    if (item.unit === "unit") {
                        updateQuantity(item.quantity, item.id);
                    } else if (item.unit === "Sq.ft.") {
                        updateQuantity(item.sqft, item.id);
                    }
                    fetchNewId();
                    console.log("insertBillList successfull, index: ", index);
                })

                .catch((err) => {
                    console.log("failed to insertBillList, error: ", err);
                });
        });
    };

    // save Bill
    const saveBill = () => {
        let url = API_URL;

        const newDate = moment(new Date()).format("YYYY-MM-DD");
        let query;

        query = `INSERT INTO gstBill (billNumber,receiverName, partyName, mobileNo, address, date, gst, total, paid, balance, paymentMode) values(
            '${moment(new Date()).format("MMMYY/DD/hmmss").toUpperCase()}',
            '${formData.receiverName}',
            '${newPartyName}',
           '${activePartyMobile}',
           '${activePartyAddress}',
           '${newDate}',
           ${netAmount * 0.18},
           ${netAmount * 0.18 + netAmount},
           0,
           ${netAmount * 0.18 + netAmount},
           ${priceMode} 
            );`;

        console.log(query);

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(API_URL, data)
            .then((res) => {
                toast.success("Bill. generated successfully");
                setBillId(res.data.insertId);
                insertBillList(res.data.insertId);
            })
            .catch((err) => {
                toast.error("Failed to Generate Bill ");
            });
    };

    const saveLedger = () => {
        let url = API_URL;

        const query = `INSERT INTO ledger(party_id, particular, total, balance) values(${partyId},"${currentProduct}",${netAmount * 0.18 + netAmount
            },${netAmount * 0.18 + netAmount})`;
        console.log(query);

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                // toast.success("added into ledger");
            })
            .catch((err) => {
                toast.error("Failed to add in ledger ");
            });
    };

    const fetchNewId = () => {
        let url = API_URL;
        const query = `select max(id)+1 as newId from gstBill;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                let data = [];

                setNewId(res.data[0].newId);
                console.log(res.data[0].newId);
            })
            .catch((err) => {
                console.log("New Id fetch error: ", err);
            });
    };

    // fetching data
    const fetchProductList = () => {
        let url = API_URL;
        const query = `select id, name as label, price, unit from products where status = 1`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                let data = [];

                setProducts(res.data);
                console.log(res.data);
            })
            .catch((err) => {
                console.log("products fetch error: ", err);
            });
    };

    const addParty = (e) => {
        e.preventDefault();
        const activePartyType = 1;

        let url = API_URL;

        const query = `INSERT INTO party (name, mobile, address,type) VALUES('${activePartyName}', '${activePartyMobile}', '${activePartyAddress}',${activePartyType})`;
        console.log(query);

        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(url, data)
            .then((res) => {
                toast.success("party details added successfully");
                setTimeout(() => {
                    refresh();
                }, 2000);
                // window.location.reload();
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const refresh = () => {
        window.location.reload(false);
    };

    const fetchPartyList = () => {
        let url = API_URL;
        const query = `SELECT id,name, mobile, address, type FROM party where type=1 and status = 1;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                let data = [];

                setParties(res.data);
                console.log(res.data);
            })
            .catch((err) => {
                console.log("Party data fetch error: ", err);
            });
    };

    const renderAddPartyModal = () => {
        return (<Modal
            show={showAddModal}
            onHide={(e) => setShowAddModal(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add New Party
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form noValidate autoComplete="off">
                    <div className="mt-3">
                        <Row>
                            <Col size="12">
                                <TextField
                                    id="partyName"
                                    label="Party name"
                                    variant="outlined"
                                    className="m-2"
                                    defaultValue=""
                                    onChange={(e) => {
                                        setActivePartyName(e.target.value)
                                    }}

                                />
                            </Col>
                            <Col>
                                <TextField
                                    id="mobile"
                                    label="Mobile"
                                    variant="outlined"
                                    className="m-2"
                                    defaultValue=""
                                    onChange={(e) =>
                                        setActivePartyMobile(e.target.value)
                                    }
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextField
                                    id="address"
                                    s
                                    label="Address"
                                    variant="outlined"
                                    className="m-2"
                                    defaultValue=""
                                    onChange={(e) =>
                                        setActivePartyAddress(e.target.value)
                                    }
                                />
                            </Col>

                        </Row>
                    </div>
                    <hr />
                    <div className="mt-2 mr-1">
                        <Btn1
                            style={{ float: "right" }}
                            onClick={(e) => {
                                setShowAddModal({
                                    showAddModal: false,
                                });
                                addParty(e);
                            }}
                        >
                            Add
                        </Btn1>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
        );
    };
    useEffect(() => {
        fetchProductList();
        insertBillList();
        fetchPartyList();
        fetchNewId();
    }, []);

    return (
        <div>
            <div className="">
                <div class="btn-group" role="group" aria-label="Basic example">
                    <Button
                        className="mt-1 mr-1 mb-3"
                        color="secondary"
                        variant="contained"
                        onClick={(e) => {
                            setShowAddModal(true);
                            console.log("hii");
                        }}
                    >
                        add new Party
                    </Button>

                    {/* <Button
                            color="primary"
                            variant="contained"
                            className="mt-1 mr-1 mb-3 ml-5"
                            onClick={this.refresh}
                        >
                            <FontAwesomeIcon icon={faSyncAlt} size="2x" />
                        </Button> */}
                </div>

                <div className="form mt-4 mb-0">
                    <div className="inputesC1 mb-2">
                        <Autocomplete
                            id="free-solo-dem"
                            className="inputFieldp mx-2 mt-1"
                            options={
                                parties != null
                                    ? parties.map(
                                        (item) => item.id + ", " + item.name
                                    )
                                    : []
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    // label="party name"
                                    label="Name"
                                    variant="outlined"
                                    size="small"
                                    value={newPartyName}
                                    // onChange={(event) =>
                                    //     setNewPartyname({ newPartyName: event.target.value })
                                    //   }
                                    oninput={(event) =>
                                        setNewPartyname(event.target.value)
                                    }
                                />
                            )}
                            onChange={(e, value) => {
                                console.log("in party change", value);
                                if (value != null && value.length > 2) {
                                    setPartyId(value.split(", ")[0]);
                                    setNewPartyname(value.split(", ")[1]);
                                    setPartyDetails(value.split(", ")[0]); // //});
                                }
                            }}
                        />

                        <TextField
                            id="outlined-basic"
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    receiverName: e.target.value,
                                })
                            }
                            required="true"
                            size="small"
                            label="Receiver Name"
                            className="inputFields  "
                            name="receiverName"
                            type="text"
                            variant="outlined"
                        />

                        <TextField
                            id="standard-basic"
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    activePartyAddress: e.target.value,
                                })
                            }
                            required="true"
                            size="small"
                            label="Address"
                            className="inputField  mx-2"
                            name="address"
                            type="text"
                            variant="standard"
                            style={{ width: "200px" }}
                            value={activePartyAddress}
                        />

                        <TextField
                            id="outlined-basic"
                            size="small"
                            label="number"
                            type="tel"
                            className="inputField  mx-2"
                            name="Phone"
                            variant="outlined"
                            required="true"
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    activePartyMobile: e.target.value,
                                })
                            }
                            value={activePartyMobile}
                            style={{ width: "200px" }}
                        />

                        <TextField
                            id="outlined-basic"
                            format="MM/DD/YYY"
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    date: e.target.value,
                                })
                            }
                            size="small"
                            style={{ width: "200px" }}
                            type="date"
                            value={formData.date}
                            // options={products != null ? products : []}
                            views="                     "
                            className="inputField  mx-2"
                            name="Date"
                            variant="outlined"
                        />

                        <ButtonGroup>
                            {PaymentMode.map((radio, idx) => (
                                <ToggleButton
                                    key={idx}
                                    id={`paymentmode-${idx}`}
                                    type="radio"
                                    variant={
                                        idx % 2
                                            ? "outline-success"
                                            : "outline-success"
                                    }
                                    name="paymentmode"
                                    required="true"
                                    value={radio.value}
                                    checked={priceMode === radio.value}
                                    onChange={(e) => {
                                        setpriceMode(e.currentTarget.value);
                                        setpriceModeName(radio.name);
                                    }}
                                    className="ToggleClassBtnRadio"
                                >
                                    {radio.name}
                                    {/* {setpriceModeName(radio.name)} */}
                                </ToggleButton>
                            ))}
                        </ButtonGroup>
                        <TextField
                            id="outlined-basic"
                            size="small"
                            label="GST Number"
                            type="text"
                            className="inputField  mx-2"
                            name="gstNumber"
                            variant="outlined"
                            required="true"
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    gstNumber: e.target.value,
                                })
                            }
                            style={{ width: "200px" }}
                        />
                    </div>
                    <hr />
                    {/* <div>
                        <button className="btn btn-sm btn-danger px-5 pt-2">
                            <h6>{["Mediator", "Retailer"][currentType - 1]}</h6>
                        </button>
                    </div> */}
                    <div className="  inputes2 mt-2 mb-4 ">
                        <Autocomplete
                            id="free-solo-demo"
                            className="inputFieldp mt-3 "
                            options={products != null ? products : []}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    // label="party name"
                                    label="Product"
                                    variant="outlined"
                                    size="small"
                                    value={currentProduct}
                                />
                            )}
                            onChange={(event, value) => {
                                if (value != null) {
                                    console.log(value.id);
                                    setId(value.id);
                                    setCurrentProduct(value.label);
                                    setCurrentUnit(value.unit);
                                    updateCurrentPrice("product", value.price);
                                }
                            }}
                        />
                        {/* <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={Unit}
                            className="inputField  mx-2"
                            size="small"
                            // style={{ width: "150px" }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Unit"
                                    value={currentUnit}
                                />
                            )}
                            defaultValue="Sq.ft."
                            onChange={(event, value) => {
                                if (value != null) {
                                    updateCurrentPrice("unit", value.label);
                                }
                            }}
                        /> */}
                        <TextField
                            id="description"
                            label="Description"
                            variant="outlined"
                            size="small"
                            className="inputField  px-2 "
                            // style={{ width: "130px" }}
                            value={description}
                            onChange={(e) => {
                                console.log(description)
                                setDescription(e.target.value);
                                
                            }}
                        />

                        <TextField
                            id="unit"
                            label="Unit"
                            variant="outlined"
                            size="small"
                            className="inputField  px-2 "
                            // style={{ width: "130px" }}
                            value={currentUnit == 1 ? "Sq. ft." : "Unit"}
                        />
                        <TextField
                            id="unitPrice"
                            label="Unit Price / sq.ft."
                            variant="outlined"
                            size="small"
                            name="unitPrice"
                            className="inputField  px-2"
                            // style={{ width: "130px" }}
                            value={currentUnitPrice}
                            onChange={(e) => {
                                console.log(currentUnitPrice)
                                setCurrentUnitPrice(e.target.value);
                                updateCurrentPrice("unitPrice", e.target.value);
                            }}
                        />
                        <TextField
                            id="outlined-basic"
                            // value={34}
                            size="small"
                            label="Width"
                            className="inputField  px-2"
                            name="width"
                            type="number"
                            variant="outlined"
                            // style={{ width: "100px" }}
                            value={currentWidth}
                            onChange={(e) => {
                                updateCurrentPrice("width", e.target.value);
                            }}
                        />
                        <TextField
                            id="outlined-basic"
                            // value={34}
                            size="small"
                            label="Height"
                            className="inputField  px-2"
                            name="height"
                            type="number"
                            variant="outlined"
                            // style={{ width: "100px" }}
                            value={currentHeight}
                            onChange={(e) => {
                                updateCurrentPrice("height", e.target.value);
                            }}
                        />
                        <TextField
                            id="outlined-basic"
                            // value={34}
                            size="small"
                            label="Quantity"
                            className="inputField  px-2"
                            name="quantity"
                            type="number"
                            variant="outlined"
                            // style={{ width: "100px" }}
                            value={currentQuantity}
                            onChange={(e) => {
                                updateCurrentPrice("quantity", e.target.value);
                            }}
                        />

                        <TextField
                            id="sqft"
                            size="small"
                            label="Sq.ft."
                            className="inputField  px-2"
                            name="sqft"
                            variant="outlined"
                            // style={{ width: "150px" }}
                            value={currentSqft}
                            readOnly
                        />

                        <TextField
                            id="price"
                            size="small"
                            label="Price"
                            className="inputField  px-2"
                            name="Price"
                            type="number"
                            variant="outlined"
                            // style={{ width: "150px" }}
                            value={currentPrice}
                            onChange={(e) => {
                                setCurrentPrice(e.target.value);
                            }}
                        />

                        {/* <Button
                            variant="contained"
                            className="AddButton"
                            onClick={listOfItems}
                        >
                            Add
                        </Button> */}
                        <Button
                            variant="contained"
                            className="AddButtons mt-3"
                            onClick={listOfItems}
                            disabled={
                                !activePartyAddress ||
                                !activePartyMobile ||
                                !priceModeName
                            }
                        >
                            Add
                        </Button>
                    </div>
                </div>

                {/* Overview */}

                <div
                    className="ReceptContainer"
                    ref={(el) => setPrintComponentRef(el)}
                >
                    <div className="text-center">
                        <div className="header  ReceptBg ">
                            <div class="logoContainer">
                                <div
                                    class="col-sm-3  col-md-3 text-start"
                                    style={{ width: "20.666%" }}
                                >
                                    <img
                                        src={image}
                                        alt="logo"
                                        className="img-fluid"
                                    />
                                </div>

                                <div class="col-sm-9  col-md-9">
                                    <p className="py-3 pl-4 text-center">
                                        <h1>
                                            <b>Ajit Arts</b>
                                        </h1>
                                        <br />
                                        <p>
                                            Near H.P.College, Mayani Road, VITA,
                                            Dist.Sangali, Maharashtra
                                            <br />
                                            GSTIN : 27AOCPP1872P1ZC
                                            &nbsp;&nbsp;&nbsp; PIN : 415311
                                        </p>
                                        <br />
                                        <p style={{ marginTop: "-2em" }}>
                                            Prop.Mr. Ajit Shamrao Patil
                                            <br />
                                            MOB. 9822441697 &nbsp;&nbsp;&nbsp;
                                            PAN NO: AOCPP1872P
                                        </p>
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* <h5 className="bg-secondary text-white px-2">
                            TAX INVOICE
                        </h5> */}
                        <div class="container">
                            <table
                                style={{
                                    backgroundColor: "black",
                                    color: "white",
                                }}
                            >
                                <tr>
                                    <th> DIGITAL PRINTING</th>
                                    <th>LED SIGN BOARD</th>
                                    <th>RADIUM CUTTING</th>
                                    <th>LASER CUTTING</th>
                                    <th>CNC ROUTER</th>
                                    <th>CHANNEL BENGING</th>
                                    <th>TROPHY HOUSE</th>
                                    <th>ART GALLERY</th>
                                    <th>GLOW SIGN BOARD</th>
                                </tr>
                            </table>

                            {/* <h6 className="bg-secondary text-secondary px-2">.</h6> */}

                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={7} align="left">
                                        <div class="details">
                                            <p>
                                                Party Name: &nbsp;{" "}
                                                {newPartyName}{" "}
                                            </p>
                                            <p>
                                                Phone No: &nbsp;
                                                {activePartyMobile}
                                            </p>
                                            <p>
                                                Address : &nbsp;{" "}
                                                {activePartyAddress}
                                            </p>
                                            <p>
                                                Payment Mode :&nbsp;
                                                {priceModeName}
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4} align="left">
                                        <div class="invoiceDetails">
                                            <p>Invoice NO : {newId}</p>
                                            <p>
                                                Invoice Date :{" "}
                                                {formData.date == null
                                                    ? moment(new Date()).format(
                                                        "D/M/YYYY"
                                                    )
                                                    : moment(
                                                        formData.date
                                                    ).format("D/M/YYYY")}{" "}
                                            </p>
                                            <p>
                                                GSTTIN NO. :{" "}
                                                {formData.gstNumber}
                                            </p>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                            <hr
                                style={{ color: "black", marginTop: "-.1em" }}
                            />
                            <table>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Product</th>
                                    <th>Description</th>
                                    <th>Quantity</th>
                                    <th>Rate / sq.ft.</th>
                                    <th>Sq.ft. / Qty</th>
                                    <th>Unit</th>

                                    <th>Total</th>
                                    <th className="d-print-none">Action</th>
                                </tr>

                                {items.map((item, id) => {
                                    return (
                                        <tr key={id}>
                                            <td>{id + 1}</td>
                                            <td>{item.product}</td>
                                            <td>{item.description}</td>
                                            <td>{item.quantity}</td>
                                            <td>
                                                {(
                                                    item.price / item.sqft
                                                ).toFixed(2)}
                                            </td>
                                            <td>{item.sqft}</td>
                                            <td>{item.unit}</td>

                                            <td>
                                                {Number(item.price).toFixed(2)}
                                            </td>
                                            <td className="d-print-none">
                                                <span
                                                    onClick={() =>
                                                        deleteItem(id)
                                                    }
                                                >
                                                    {" "}
                                                    <DeleteIcon color="error" />{" "}
                                                </span>
                                            </td>
                                        </tr>
                                    );
                                })}

                                <br />
                                <tr></tr>
                                <tr>
                                    <td colSpan={6}>Total amount before tax</td>
                                    <td></td>
                                    <td style={{textAlign:'center'}}>{netAmount}</td>
                                </tr>
                                <tr>
                                    <td colSpan={6}>SGST 9%</td>
                                    <td></td>
                                    <td style={{textAlign:'center'}}>{(netAmount * 0.09).toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td colSpan={6}>CGST 9%</td>
                                    <td></td>
                                    <td style={{textAlign:'center'}}>{(netAmount * 0.09).toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td colSpan={6}>
                                        
                                        <b>Grand Total</b>
                                    </td>
                                    <td></td>
                                    <td style={{textAlign:'center'}}>
                                        <b>
                                            {(
                                                netAmount * 0.18 +
                                                netAmount
                                            ).toFixed(2)}
                                        </b>
                                    </td>
                                </tr>
                            </table>

                            <hr />
                            {/* <div className="row">
                                <div className="col-6 col-md-6">
                                    <h6>
                                        Certified that the particulars given
                                        above are true and correct
                                    </h6>
                                    <p style={{ marginTop: "1em" }}>
                                        Rs. In
                                        Words__________________________________
                                    </p>
                                </div>

                                {/* <div className="col-2 col-md-2">
                                <img
                                    src="/Assets/QrCode1.jpg"
                                    height="100"
                                    width="100"
                                />
                            </div> */}
                            
                            
                            <div className="row" style={{marginTop:'1em'}}>
                                <div className="col-6">
                                    <div style={{marginTop:'4em'}}>
                                    <p style={{ marginTop: "-1em", float: 'left', marginLeft: '5em' }}>
                                        Acc No. 150930110000199
                                    </p>
                                    <p style={{ marginTop: "-1em", float: 'left', marginLeft: '5em' }}>
                                        IFSC Code: BKID0001509
                                    </p>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div>
                                    <p align="left" style={{marginBottom:'-3em',marginTop:'1em'}} >
                                        <b>{formData.receiverName}<br />
                                        </b>
                                    </p>
                                    <p align="left" style={{ marginTop: "3.5em" }}>
                                        Receivers Signature
                                    </p>
                                    <p
                                        align="right"
                                        style={{
                                            marginTop: "-2.5em",
                                            marginRight: "5em",
                                        }}
                                    >
                                        For Ajit Arts,Vita
                                    </p>
                                    </div>
                                </div>
                            </div>
                    </div>
                        
                    </div>
                </div>

                {/* invoice */}

                <ReactToPrint content={() => printComponentRef}>
                    <PrintContextConsumer>
                        {({ handlePrint }) => (
                            <Button
                                onClick={handlePrint}
                                className="mb-5 mt-0 mr-3"
                                color="primary"
                                variant="contained"
                                style={{ float: "right" }}
                                disabled={saveFlag == true ? false : true}
                            >
                                PRINT
                            </Button>
                        )}
                    </PrintContextConsumer>
                </ReactToPrint>
                {/* save Bill */}
                <Button
                    className=" mr-3"
                    color="secondary"
                    variant="contained"
                    style={{ float: "right", marginRight: "20px" }}
                    // type="submit"
                    onClick={(e) => {
                        setSaveFlag(true);
                        saveBill();
                        saveLedger();
                    }}
                    disabled={!activePartyAddress || !activePartyMobile}
                >
                    SAVE BILL
                </Button>
            </div>
            {renderAddPartyModal()}
        </div>
    );
};

export default GSTBill;

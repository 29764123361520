import React, { Component } from "react";
import { Badge, Card, Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

// datatable
import "jquery/dist/jquery.min.js";

import "datatables.net-dt/js/dataTables.dataTables";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import Button from "@mui/material/Button";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from "@mui/icons-material/Preview";

import TextField from "@mui/material/TextField";
//API handling components
import { API_URL } from "../../Global";

// datatable
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTrash } from "@fortawesome/free-solid-svg-icons";

const axios = require("axios");

export class ExpensesManager extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showDeleteModal: false,
            description: "",
            amount: "",
            name: "",
            expenseData: null,
            activeExpenseId: null,
            selectedFile: null,
            activeDocPath: null,
        };
    }
    getExpenseData() {
        const query = `SELECT * from expenses order by id desc;`;
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(API_URL, data)
            .then((res) => {
                console.log(res.data);
                this.setState({ expenseData: res.data });
                console.log(res.data);
                // this.initializeDataTable();
            })
            .catch((err) => {
                console.log(err);
                toast("failed to fetch");
            });
    }
    componentDidMount() {
        this.getExpenseData();
    }

    handleInputChange = (event) => {
        this.setState({
            selectedFile: event.target.files[0],
        });
    };

    submitFile() {
        const data = new FormData();
        data.append("fileToUpload", this.state.selectedFile);
        let url = "https://imprint24.5techg.com/fileUpload/index.php";

        axios.post(url, data).then((res) => {
            // then print response status
            this.setState({ activeDocPath: res.data }, this.handleSubmit);
        });
    }

    handleSubmit() {
        const { description, amount, name, activeDocPath } = this.state;
        const date = new Date();
        const query = `INSERT INTO expenses (description,date,name,amount, docPath) VALUES('${description}','${moment(
            date
        ).format()}', '${name}', ${amount}, "${activeDocPath}");`;
        console.log(query);
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(API_URL, data)
            .then((res) => {
                toast("expense saved successfully");
                setTimeout(this.refresh, 3000);
            })
            .catch((err) => {
                console.log(err);
                toast("failed to save");
            });
    }

    deleteExpense(id) {
        const query = `DELETE from expenses WHERE id=${id};`;
        console.log(query);
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(API_URL, data)
            .then((res) => {
                toast.success("expense deleted successfully");
                this.refresh();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    componentDidUpdate() {
        $("#expenses_table")
            .DataTable({
                destroy: true,
                keys: true,
                dom:
                    "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
                    "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
                    "<'row'<'col-sm-12' tr>>" +
                    "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
                responsive: true,
                lengthChange: false,
                autoWidth: false,
                buttons: ["csv", "print"],
                order: [[0, "desc"]],
            })
            .buttons()
            .container();
    }

    refresh() {
        window.location.reload(false);
    }

    renderExpenses = () => {
        if (this.state.expenseData == null) return null;
        return this.state.expenseData.map((expense) => {
            return (
                <tr key={expense.id}>
                    <td align="center">
                        <Badge variant="primary"> {expense.id}</Badge>
                    </td>
                    <td> {expense.name}</td>
                    <td>₹ {expense.amount}</td>
                    <td
                        style={{
                            textTransform: "capitalize",
                        }}
                    >
                        {expense.description}
                    </td>

                    <td>{moment(expense.date).format("DD/MM/YYYY")}</td>
                    <td align="center">
                        {expense.docPath != "File upload failed" && (
                            <a
                                href={`${expense.docPath}`}
                                download
                                target="_blank"
                                className="disabled"
                            >
                                <PreviewIcon color="success" />
                            </a>
                        )}
                        <DeleteIcon
                            onClick={(e) => {
                                this.setState({
                                    activeExpenseId: expense.id,
                                });
                                this.setState({
                                    showDeleteModal: true,
                                });
                            }}
                            color="error"
                        />
                        <Modal
                            show={this.state.showDeleteModal}
                            onHide={(e) =>
                                this.setState({
                                    showDeleteModal: false,
                                })
                            }
                            size="md"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    Delete expense record
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>
                                    Do you really want to delete this expense?
                                </p>
                                <button
                                    color="danger"
                                    variant="contained"
                                    className="mt-1 mb-1"
                                    onClick={() => {
                                        this.deleteExpense(
                                            this.state.activeExpenseId
                                        );
                                        this.setState({
                                            showDeleteModal: false,
                                        });
                                    }}
                                >
                                    Delete
                                </button>
                            </Modal.Body>
                        </Modal>
                    </td>
                </tr>
            );
        });
    };
    render() {
        return (
            <div className="container-fluid">
                <Card>
                    <Card.Body className="mt-0 pt-3">
                        <Card.Title>Manage Expenses</Card.Title>
                        <div>
                            <div className="mt-3 inputesC1 ">
                                <TextField
                                    id="amount"
                                    label="Amount"
                                    variant="outlined"
                                    type="number"
                                    size="small"
                                    value={this.state.amount}
                                    className="mr-3 inputField "
                                    // style={{ width: "100px" }}
                                    onChange={(e) =>
                                        this.setState({
                                            amount: e.target.value,
                                        })
                                    }
                                />
                                <TextField
                                    id="name"
                                    label="Name"
                                    variant="outlined"
                                    type="text"
                                    size="small"
                                    value={this.state.name}
                                    className="mr-3 inputField"
                                    // style={{ width: "100px" }}
                                    onChange={(e) =>
                                        this.setState({
                                            name: e.target.value,
                                        })
                                    }
                                />
                                <TextField
                                    id="description"
                                    label="Description"
                                    variant="outlined"
                                    size="small"
                                    value={this.state.description}
                                    className="mr-3 inputField"
                                    // style={{ minWidth: "30vw" }}
                                    onChange={(e) =>
                                        this.setState({
                                            description: e.target.value,
                                        })
                                    }
                                />
                                <TextField
                                    name="fileToUpload"
                                    size="small"
                                    type="file"
                                    variant="outlined"
                                    className="mr-3 inputField"
                                    onChange={this.handleInputChange}
                                />

                                <button
                                    className="btn btn-primary"
                                    onClick={(e) => this.submitFile()}
                                >
                                    Add expense
                                </button>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <div
                    // component={''}
                    style={{ width: "100%" }}
                    className="mt-2"
                >
                    <table
                        id="expenses_table"
                        className="display"
                        style={{ width: "100%" }}
                    >
                        <thead>
                            <tr>
                                <th align="center">Expense Id</th>
                                <th>Name</th>
                                <th>Amount</th>
                                <th>Description</th>
                                <th>Date</th>
                                <th align="center">Option</th>
                            </tr>
                        </thead>
                        <tbody>{this.renderExpenses()}</tbody>
                    </table>
                </div>
                <ToastContainer
                    position={toast.POSITION.TOP_RIGHT}
                    autoClose={3000}
                />
            </div>
        );
    }
}

export default ExpensesManager;
